<template>
    <div class="index">
        <!-- 渲染引擎 -->
        <CesiumModel :activeModel="activeModel" @catchIDEvent="catchIDEvent"></CesiumModel>
        <MaskShadow v-if="isLoading"></MaskShadow>
        <!-- 顶部 组件库 -->
        <!-- <WorldTop @screenChange="screenChange()"></WorldTop> -->
        <TopMenuSituation v-if="activeFirstMenu=='综合态势'" class="top-animate" @chooseMenu="swtchSecondMenu"></TopMenuSituation>
        <TopMenuRevitalize v-else-if="activeFirstMenu=='乡村振兴'" class="top-animate" @chooseMenu="swtchSecondMenu"></TopMenuRevitalize>
        <TopMenuSafe v-else-if="activeFirstMenu=='平安乡村'" class="top-animate" @chooseMenu="swtchSecondMenu"></TopMenuSafe>
        <!-- 底部 组件库 -->
        <WorldBottom @switchMenu="switchFirstMenu"></WorldBottom>
        <BottomSituation v-if="activeFirstMenu=='综合态势'" class="left-animate" @chooseBottomBtn="swtchSecondMenu"></BottomSituation>
        <!-- 弹窗 -->
        <transition name="fade" appear>
            <!-- 自建房 -->
            <div v-if="dialogZJF" class="diy-model">
                <ZJFModel @close="dialogZJF=false"></ZJFModel>
            </div>
            <!-- 民宅 -->
            <div v-if="dialogMZ" class="diy-model">
                <MZModel :mzID="mzID" @close="dialogMZ=false"></MZModel>
            </div>
            <!-- 农化科技有限公司 -->
            <div v-if="dialogNHKJYXGS" class="diy-model">
                <NHKJYXGSModel @close="dialogNHKJYXGS=false"></NHKJYXGSModel>
            </div>
            <!-- 桃园卫生服务中心 -->
            <div v-if="dialogWSFUZXM" class="diy-model">
                <WSFUZXModel @close="dialogWSFUZXM=false"></WSFUZXModel>
            </div>
            <!-- 桃园幼儿园 -->
            <div v-if="dialogYEY" class="diy-model">
                <YEYModel @close="dialogYEY=false"></YEYModel>
            </div>
            <!-- 消防事件 -->
            <div v-if="dialogSJZL" class="diy-model">
                <SJZLModel @close="dialogSJZL=false" :eventInfo="eventInfoSJZL"></SJZLModel>
            </div>
            <!-- 视频监控 -->
            <div v-if="dialogVIDEOS" class="diy-model">
                <VIDEOSModel @close="dialogVIDEOS=false" :ID="VideoID" :videosInfo="videosInfo"></VIDEOSModel>
            </div>
            <!-- 党员包保 -->
            <div v-if="dialogDYBB" class="diy-model">
                <DYBBModel @close="dialogDYBB=false" :userInfo="userInfo"></DYBBModel>
            </div>
        </transition>
    </div>
</template>

<script>
/* 渲染引擎 */
import CesiumModel from '@/components/CesiumModel.vue'
/* 组件分块 - 加载遮罩 */
import MaskShadow from '@/components/MaskShadow.vue'
/* 组件分块 - 顶部 */
import WorldTop from '@/components/WorldTop.vue'
import TopMenuSituation from '@/components/TopMenuSituation.vue'
import TopMenuRevitalize from '@/components/TopMenuRevitalize.vue'
import TopMenuSafe from '@/components/TopMenuSafe.vue'
/* 组件分块 - 底部 */
import WorldBottom from '@/components/WorldBottom.vue'
import BottomSituation from '@/components/BottomSituation.vue'
/* 组件分块 - 弹窗 */
import ZJFModel from '@/components/models/ZJF.vue'
import NHKJYXGSModel from '@/components/models/NHKJYXGS.vue'
import WSFUZXModel from '@/components/models/WSFUZX.vue'
import YEYModel from '@/components/models/YEY.vue'
import SJZLModel from '@/components/models/SJZL.vue'
import VIDEOSModel from '@/components/models/VIDEOS.vue'
import DYBBModel from '@/components/models/DYBB.vue'
import MZModel from '@/components/models/MZ.vue'
export default {
    components:{
        CesiumModel,
        MaskShadow,WorldTop,
        TopMenuSituation,TopMenuRevitalize,TopMenuSafe,
        WorldBottom,BottomSituation,
        ZJFModel,NHKJYXGSModel,WSFUZXModel,YEYModel,VIDEOSModel,
        DYBBModel,SJZLModel,MZModel
    },
    data(){
        return{
            /* 渲染引擎 */
            isLoading: true,
            activeModel: '',
            /* 底部菜单 */
            activeFirstMenu: '综合态势',
            activeSecondMenu: '',
            /* 弹窗 */
            dialogZJF: false, // 自建房
            dialogNHKJYXGS: false, // 农化科技有限公司
            dialogWSFUZXM: false, // 卫生服务中心
            dialogYEY: false, // 幼儿园
            dialogSJZL: false, // 消防事件
            dialogMZ: false, // 民宅
            mzID: '',
            eventInfoSJZL: {
                name: '----',
                time: '----/--/-- --:--:--',
                result: '----',
                cancel: '----',
                cancelText: '----------',
                ok: '----',
                okText: '------------'
            },
            dialogVIDEOS: false, // 视频监控
            videosInfo: {
                name: '------'
            },
            dialogDYBB: false, // 党员包保
            userInfo: {
                name: '唐成保'
            },
            VideoID: '5'
        }
    },
    mounted() {
        setTimeout(() =>{
            this.isLoading = false
        },5000)
        // 渲染第一组
        this.activeModel = 'POI'
    },
    methods:{
        /* 一级菜单 */
        switchFirstMenu(val){
            this.activeFirstMenu = val
            switch (val){
                case '综合态势':
                    this.swtchSecondMenu('企业公司')
                    break;
                case '平安乡村':
                    
                    break;
                case '乡村振兴':
                    this.swtchSecondMenu('党建引领')
                    break;
                case '在线旅游':
                    break;
                default:
                    break;
            }
            this.activeModel = val
        },
        /* 二级菜单 */
        swtchSecondMenu(val){
            this.activeSecondMenu = val
            switch (val){
                case '企业公司':
                    break;
                case '医疗诊所':
                    break;
                case '自建房':
                    break;
                case 'POI':
                    break;
                case '村貌分布':
                    break;
                case '事件总览':
                    break;
                case '水域安全':
                    break;
                case '摄像头':
                    break;
                case '重点人员':
                    break;
                case '党建引领':
                    break;
                case '党员包保':
                    break;
                default:
                    break;
            }
            this.activeModel = val
        },
        /* ************************* 渲染引擎相关 ************************* */
        /* 屏幕大小改变 */
        screenChange(){
            
        },
        /* 选中目标 */
        catchIDEvent(item){
            console.log(item)
            switch (item.id){
                // 桃园卫生服务中心
                case 'POI#01':
                    this.dialogWSFUZXM = true
                    break;
                // 农化科技有限公司
                case 'POI#02':
                    this.dialogNHKJYXGS = true
                    break;
                // 自建房
                case 'POI#03':
                    this.dialogZJF = true
                    break;
                // 民宅
                case 'POI#mz01':case 'POI#mz10':case 'POI#mz17':case 'POI#mz24':case 'POI#mz27':
                    this.mzID = item.id
                    this.dialogMZ = true
                    break;
                // 桃园幼儿园
                case 'POI#04':
                    this.dialogYEY = true
                    break;
                // 房屋违建
                case '事件类型#01':
                    this.eventInfoSJZL = {
                        name: '房屋违建',
                        time: '2022-07-18 14:00:12',
                        result: '等待上报',
                        cancel: '暂不处理',
                        cancelText: '违建事件已忽略，请持续关注',
                        ok: '通知强停',
                        okText: '违建拆除通知已下达'
                    }
                    this.dialogSJZL = true
                    break;
                // 环境整治
                case '事件类型#02':
                    this.eventInfoSJZL = {
                        name: '环境整治',
                        time: '2022-07-18 14:00:12',
                        result: '等待上报',
                        cancel: '暂不处理',
                        cancelText: '事件已忽略',
                        ok: '立即上报',
                        okText: '环境问题登记成功'
                    }
                    this.dialogSJZL = true
                    break;
                // 邻里矛盾
                case '事件类型#03':
                    this.eventInfoSJZL = {
                        name: '邻里矛盾',
                        time: '2022-07-18 14:00:12',
                        result: '等待上报',
                        cancel: '暂不处理',
                        cancelText: '事件已忽略',
                        ok: '下达调解',
                        okText: '调解通知成功下达指定人员'
                    }
                    this.dialogSJZL = true
                    break;
                // 消防事件
                case '事件类型#04':
                    this.eventInfoSJZL = {
                        name: '消防事件',
                        time: '2022-07-18 14:00:12',
                        result: '等待上报',
                        cancel: '处置案例',
                        cancelText: '案例处置成功',
                        ok: '报警联动',
                        okText: '报警响应已开启'
                    }
                    this.dialogSJZL = true
                    break;
                // 视频监控
                case '水域监控#01':
                    this.VideoID = '3'
                    this.videosInfo.name = item.name
                    this.dialogVIDEOS = true
                    break;
                case '水域监控#02':case '水域监控#03':case '水域监控#04':
                    this.VideoID = '4'
                    this.videosInfo.name = item.name
                    this.dialogVIDEOS = true
                    break;
                case '高清监控#01':case 'POI#VIDEO#01':
                    this.VideoID = '5'
                    this.videosInfo.name = item.name
                    this.dialogVIDEOS = true
                    break;
                case 'POI#VIDEO#02': case '高清监控#02':case '高清监控#03':case '高清监控#04':case '高清监控#05':case '高清监控#06':case '高清监控#07':case '高清监控#08':
                    this.VideoID = '6'
                    this.videosInfo.name = item.name
                    this.dialogVIDEOS = true
                    break;
                // 党员包保
                case '党员包保#01':case '党员包保#02':case '党员包保#03':
                    this.userInfo.name = item.name
                    this.dialogDYBB = true
                    break;
                default:
                    break;
            }
        },
    },
    destroyed() {
        
    }
}
</script>

<style lang="less" scoped>
    .index{
        width: 100vw;
        height: 100vh;
        position: relative;
        min-width: 1400px;
        min-height: 900px;
        background-color: rgb(41, 76, 101);
        user-select: none;
        -webkit-user-drag: none;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .left-animate{
            animation: leftIn 0.35s linear;
            @keyframes leftIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    left: -390px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    left: 30px;
                }
            }
            @-webkit-keyframes leftIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    left: -390px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    left: 30px;
                }
            }
        }
        .right-animate{
            animation: rightIn 0.35s linear;
            @keyframes rightIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    right: -390px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    right: 30px;
                }
            }
            @-webkit-keyframes rightIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    right: -390px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    right: 30px;
                }
            }
        }
        .top-animate{
            animation: topIn 0.2s linear;
            @keyframes topIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    top: 20px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    top: 90px;
                }
            }
            @-webkit-keyframes topIn {
                0%{
                    position: absolute;
                    opacity: 0;
                    top: 20px;
                }
                100%{
                    position: absolute;
                    opacity: 1;
                    top: 90px;
                }
            }
        }
        .diy-model{
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2001;
            background-color: rgba(0,0,0,0.3);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>