<template>
    <div class="cesium">
        <div id="cesiumContainer" ref="cesiumContainer" style="width:100%;height:100%;"></div>
    </div>
</template>

<script>
import cesiumTools from "@/lib/cesiumTools.js";
import CesiumData from '@/lib/cesiumData.js'
// 飞线迁徙图 类
class LineFlowMaterialProperty {
    constructor(options) {
        this._definitionChanged = new Cesium.Event();
        this._color = undefined;
        this._speed = undefined;
        this._percent = undefined;
        this._gradient = undefined;
        this.color = options.color;
        this.speed = options.speed;
        this.percent = options.percent;
        this.gradient = options.gradient;
    };
    get isConstant() {
        return false;
    }
    get definitionChanged() {
        return this._definitionChanged;
    }
    getType(time) {
        return Cesium.Material.LineFlowMaterialType;
    }
    getValue(time, result) {
        if (!Cesium.defined(result)) {
            result = {};
        }
        result.color = Cesium.Property.getValueOrDefault(this._color, time, Cesium.Color.RED, result.color);
        result.speed = Cesium.Property.getValueOrDefault(this._speed, time, 5.0, result.speed);
        result.percent = Cesium.Property.getValueOrDefault(this._percent, time, 0.1, result.percent);
        result.gradient = Cesium.Property.getValueOrDefault(this._gradient, time, 0.01, result.gradient);
        return result
    }
    equals(other) {
        return (this === other ||
            (other instanceof LineFlowMaterialProperty &&
                Cesium.Property.equals(this._color, other._color) &&
                Cesium.Property.equals(this._speed, other._speed) &&
                Cesium.Property.equals(this._percent, other._percent) &&
                Cesium.Property.equals(this._gradient, other._gradient))
        )
    }
}
Object.defineProperties(LineFlowMaterialProperty.prototype, {
    color: Cesium.createPropertyDescriptor('color'),
    speed: Cesium.createPropertyDescriptor('speed'),
    percent: Cesium.createPropertyDescriptor('percent'),
    gradient: Cesium.createPropertyDescriptor('gradient'),
})
Cesium.LineFlowMaterialProperty = LineFlowMaterialProperty;
Cesium.Material.LineFlowMaterialProperty = 'LineFlowMaterialProperty';
Cesium.Material.LineFlowMaterialType = 'LineFlowMaterialType';
Cesium.Material.LineFlowMaterialSource =
    `
uniform vec4 color;
uniform float speed;
uniform float percent;
uniform float gradient;

czm_material czm_getMaterial(czm_materialInput materialInput){
  czm_material material = czm_getDefaultMaterial(materialInput);
  vec2 st = materialInput.st;
  float t =fract(czm_frameNumber * speed / 1000.0);
  t *= (1.0 + percent);
  float alpha = smoothstep(t- percent, t, st.s) * step(-t, -st.s);
  alpha += gradient;
  material.diffuse = color.rgb;
  material.alpha = alpha;
  return material;
}
`
Cesium.Material._materialCache.addMaterial(Cesium.Material.LineFlowMaterialType, {
    fabric: {
        type: Cesium.Material.LineFlowMaterialType,
        uniforms: {
            color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),
            speed: 10.0,
            percent: 0.1,
            gradient: 0.01
        },
        source: Cesium.Material.LineFlowMaterialSource
    },
    translucent: function(material) {
        return true;
    }
})
// tag点图标
import Buiding_01 from '@/assets/images/cesium/icon-build01.png'
import Buiding_02 from '@/assets/images/cesium/icon-build02.png'
import Buiding_03 from '@/assets/images/cesium/icon-build03.png'
import Buiding_04 from '@/assets/images/cesium/icon-build04.png'
import iconFWWJ from '@/assets/images/cesium/icon-fwwj.png'
import iconHJZZ from '@/assets/images/cesium/icon-hjzz.png'
import iconLLMD from '@/assets/images/cesium/icon-llmd.png'
import iconXFSJ from '@/assets/images/cesium/icon-xfsj.png'
import iconSYJK_01 from '@/assets/images/cesium/icon-syjk01.png'
import iconSYJK_02 from '@/assets/images/cesium/icon-syjk02.png'
import iconSYJK_03 from '@/assets/images/cesium/icon-syjk03.png'
import iconSYJK_04 from '@/assets/images/cesium/icon-syjk04.png'
import iconGQJK_01 from '@/assets/images/cesium/icon-gqjk01.png'
import iconGQJK_02 from '@/assets/images/cesium/icon-gqjk02.png'
import iconGQJK_03 from '@/assets/images/cesium/icon-gqjk03.png'
import iconGQJK_04 from '@/assets/images/cesium/icon-gqjk04.png'
import iconGQJK_05 from '@/assets/images/cesium/icon-gqjk05.png'
import iconGQJK_06 from '@/assets/images/cesium/icon-gqjk06.png'
import iconGQJK_07 from '@/assets/images/cesium/icon-gqjk07.png'
import iconGQJK_08 from '@/assets/images/cesium/icon-gqjk08.png'
import icon_safe01 from '@/assets/images/cesium/icon-safe01.png'
import icon_safe02 from '@/assets/images/cesium/icon-safe02.png'
import icon_safe03 from '@/assets/images/cesium/icon-safe03.png'
import icon_time01 from '@/assets/images/cesium/icon-time01.png'
import icon_time02 from '@/assets/images/cesium/icon-time02.png'
import icon_time03 from '@/assets/images/cesium/icon-time03.png'
import icon_time04 from '@/assets/images/cesium/icon-time04.png'
import icon_mz01 from '@/assets/images/cesium/icon-mz01.png'
import icon_mz10 from '@/assets/images/cesium/icon-mz10.png'
import icon_mz17 from '@/assets/images/cesium/icon-mz17.png'
import icon_mz24 from '@/assets/images/cesium/icon-mz24.png'
import icon_mz27 from '@/assets/images/cesium/icon-mz27.png'


// 实例缓存
var VIEWSITE = {
    _viewer: null,
    _tileset: null,
    _primitives: [],
    _personStepLines: [],
    _flowEntityCollection: null
}
export default {
    props: {
        activeModel: {
            type: String,
            default: 'POI'
        }
    },
    data(){
        return{
            // cesium插件Assess_token - 用户nameHulk
            hulk_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4MzU2N2U4Yi03YmMyLTQzNTItODU0OS00MzAzNWY1MzYzZmEiLCJpZCI6NjU3NzgsImlhdCI6MTY1ODEwNzI3NH0.nmjMbu0D-YjRJCKwRaYxjgCkpyEPI7JOzgeqpxEFqfc',
            iconDics: {
                'icon-build01': Buiding_01,
                'icon-build02': Buiding_02,
                'icon-build03': Buiding_03,
                'icon-build04': Buiding_04,
                'icon-fwwj': iconFWWJ,
                'icon-hjzz': iconHJZZ,
                'icon-llmd': iconLLMD,
                'icon-xfsj': iconXFSJ,
                'icon-syjk01': iconSYJK_01,
                'icon-syjk02': iconSYJK_02,
                'icon-syjk03': iconSYJK_03,
                'icon-syjk04': iconSYJK_04,
                'icon-gqjk01': iconGQJK_01,
                'icon-gqjk02': iconGQJK_02,
                'icon-gqjk03': iconGQJK_03,
                'icon-gqjk04': iconGQJK_04,
                'icon-gqjk05': iconGQJK_05,
                'icon-gqjk06': iconGQJK_06,
                'icon-gqjk07': iconGQJK_07,
                'icon-gqjk08': iconGQJK_08,
                'icon-safe01': icon_safe01,
                'icon-safe02': icon_safe02,
                'icon-safe03': icon_safe03,
                'icon-time01': icon_time01,
                'icon-time02': icon_time02,
                'icon-time03': icon_time03,
                'icon-time04': icon_time04,
                'icon-mz01': icon_mz01,
                'icon-mz10': icon_mz10,
                'icon-mz17': icon_mz17,
                'icon-mz24': icon_mz24,
                'icon-mz27': icon_mz27,
            }
        }
    },
    watch:{
        activeModel(val){
            this.handleModels(val)
        }
    },
    mounted(){
        this.createCesium();
    },
    methods:{
        /* 分类别加载显示元素 */
        handleModels(val){
            if(VIEWSITE._primitives.length > 0){
                VIEWSITE._primitives.forEach(child =>{
                    VIEWSITE._viewer.scene.primitives.remove(child)
                })
                VIEWSITE._primitives = []
            }
            if(VIEWSITE._personStepLines.length > 0){
                VIEWSITE._personStepLines.forEach(child =>{
                    VIEWSITE._viewer.scene.primitives.remove(child)
                })
                VIEWSITE._personStepLines = []
            }
            if(VIEWSITE._flowEntityCollection != null){
                VIEWSITE._flowEntityCollection.entities.removeAll()
                VIEWSITE._flowEntityCollection = null
            }
            switch(val){
                case '综合态势':
                    this.handleModels('POI')
                    this.flyView(VIEWSITE._viewer)
                    break;
                case '平安乡村':
                    this.handleModels('事件总览')
                    this.flyView(VIEWSITE._viewer)
                    break;
                case '乡村振兴':
                    this.handleModels('党建引领')
                    this.flyView(VIEWSITE._viewer)
                    break;
                case '在线旅游':
                    break;
                default:
                    break;
                case '企业公司':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('POI#02') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '医疗诊所':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('POI#01') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '自建房':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('POI#03') >= 0 || ele.indexOf('POI#04') >= 0 || ele.indexOf('POI#mz') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case 'POI':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('POI') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '村貌分布':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('村貌分布') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '事件总览':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('事件类型') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '水域安全':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('水域监控') >= 0 || ele == '村貌分布#03'){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '摄像头':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('高清监控') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
                case '重点人员':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('USERTIME') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    this.addTransferInfo(VIEWSITE._viewer)
                    break;
                case '党建引领':
                    CesiumData.TotalIDs.forEach(ele =>{
                        VIEWSITE._viewer.entities.getById(ele).show = false
                    })
                    // this.addFlyline(VIEWSITE._viewer)
                    this.addFlylineNew(VIEWSITE._viewer)
                    break;
                case '党员包保':
                    CesiumData.TotalIDs.forEach(ele =>{
                        if(ele.indexOf('党员包保') >= 0){
                            VIEWSITE._viewer.entities.getById(ele).show = true
                        }else{
                            VIEWSITE._viewer.entities.getById(ele).show = false
                        }
                    })
                    break;
            }
        },
        /* 初始化cesium引擎 */
        createCesium(){
            var _this = this
            // 创建viewer实例
            Cesium.Ion.defaultAccessToken = this.hulk_token;
            // 更换天地图 底图
            // let imageryProvider = new Cesium.WebMapTileServiceImageryProvider({
            //     url: 'https://t0.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=d93c1a6908004bcdde6d77c4bec51ec5',
            //     layer: 'img',
            //     style: 'default',
            //     format: 'tiles',
            //     tileMatrixSetID: 'w',
            //     credit: new Cesium.Credit('天地图全球影像服务'),
            //     maximumLevel: 18,
            //     show: false,
            // });
            let viewerOption = {
                geocoder: false,                // 地理位置搜索控件
                homeButton: false,              // 首页跳转控件
                sceneModePicker: false,         // 2D,3D和Columbus View切换控件
                baseLayerPicker: false,         // 三维地图底图切换控件
                navigationHelpButton: false,    // 帮助提示控件
                animation: false,               // 视图动画播放速度控件
                timeline: false,                // 时间轴控件
                fullscreenButton: false,        // 全屏控件
                infoBox: false,                 // 点击显示窗口控件
                selectionIndicator: false,      // 实体对象选择框控件
                scene3DOnly: true,              // 仅3D渲染，节省GPU内存
                requestRenderMode: false,       // 减少Cesium渲染新帧总时间并减少Cesium在应用程序中总体CPU使用率
                // imageryProvider: imageryProvider // 注册天地图
            }
            let viewer = new Cesium.Viewer("cesiumContainer", viewerOption);
            VIEWSITE._viewer = viewer
            viewer.scene.fxaa = false;
            // 
            const imageryLayers = viewer.imageryLayers;
            imageryLayers.remove(imageryLayers.get(0));
            // 加载Bing Map影像服务
            var bing = new Cesium.BingMapsImageryProvider({
                url : 'https://dev.virtualearth.net',
                key : 'Ap_DNxwVu3uAk2ZdrNGX4IkqwpkRTgmYNiR0EFyqciCGteS9lxowpJiol5n5wVV2',
                mapStyle : Cesium.BingMapsStyle.AERIAL
            });
            const bingImageryLayer = new Cesium.ImageryLayer(bing);
            imageryLayers.add(bingImageryLayer);
            //是否开启抗锯齿
            viewer.scene.fxaa = this.$Config.cesiumOpts.isFxaa;
            viewer.scene.postProcessStages.fxaa.enabled = this.$Config.cesiumOpts.isFxaa;
            var supportsImageRenderingPixelated = viewer.cesiumWidget._supportsImageRenderingPixelated;
            if (supportsImageRenderingPixelated) {
                var vtxf_dpr = window.devicePixelRatio;
                while (vtxf_dpr >= 2.0) { vtxf_dpr /= 2.0; }
                viewer.resolutionScale = vtxf_dpr;
            }
            // 新增3D模型
            var tileset = viewer.scene.primitives.add(
                new Cesium.Cesium3DTileset({
                    url: 'https://cdn.gis.lan.lyiot.cc/gis/ftc/tileset.json',
                    // maximumScreenSpaceError: this.$Config.cesiumOpts.isHD?1:16,
                    // maximumNumberOfLoadedTiles: this.$Config.cesiumOpts.isHD?2000:100,
                    maximumScreenSpaceError: this.$Config.cesiumOpts.isHD?10:16,
                    maximumNumberOfLoadedTiles: this.$Config.cesiumOpts.isHD?1000:100,
                }),
            );
            // 调整3D模型平移贴地
            tileset.readyPromise.then(function(tileset){
                var translation = Cesium.Cartesian3.fromArray([21, -46, -28])
                var m = Cesium.Matrix4.fromTranslation(translation)
                tileset.modelMatrix = m
            })
            // 定位3D模型
            VIEWSITE._tileset = tileset
            // viewer.zoomTo(tileset);
            // 视角
            this.setView(viewer)
            // 创建事件监听
			this.createHander(viewer);
            // 新增点位
            this.addPoints(viewer);
            // 新增区域
            this.addAreas(viewer);
        },
        /* 创建事件监听 */
        createHander(viewer){
            var _this = this
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement){
                // 捕获点击对象数据
                var pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick)) {
                    if(pick.id){
                        let entityCatch = pick.id
                        _this.tansferChooseView(entityCatch)
                    }
                }
                // 捕获三维场景当前数据
                cesiumTools.LogViewMessage(Cesium,viewer,movement).then((res) =>{
                    console.log(res, '捕获三维场景当前数据')
                })
                // 捕获当前坐标点数据
                var earthPosition = viewer.scene.pickPosition(movement.position);
                console.log(earthPosition,'当前坐标点数据')
			}, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        },
        /* 视角飞行 - 有动画 */
        flyView(viewer){
            let site = CesiumData.HomeView[0]
            viewer.camera.flyTo({
                destination : Cesium.Cartesian3.fromDegrees(site.lon, site.lat, site.alt),
                orientation : {
                    heading : Cesium.Math.toRadians(site.heading),
                    pitch : Cesium.Math.toRadians(site.pitch),
                    roll : Cesium.Math.toRadians(site.roll),
                }
            });
        },
        /* 视角定位 - 无动画 */
        setView(viewer){
            let site = CesiumData.HomeView[0]
            viewer.camera.setView({
                destination : Cesium.Cartesian3.fromDegrees(site.lon, site.lat, site.alt),
                orientation : {
                    heading : Cesium.Math.toRadians(site.heading),
                    pitch : Cesium.Math.toRadians(site.pitch),
                    roll : Cesium.Math.toRadians(site.roll),
                }
            });
        },
        /* 选中元素信息 */
        tansferChooseView(entityCatch){
            let opts = {
                status: entityCatch.status,
                type: entityCatch.type,
                etype: entityCatch.etype,
                id: entityCatch.id,
                name: entityCatch.name
            }
            this.$emit('chooseElement', opts)
            // 跳跃视角
            VIEWSITE._viewer.flyTo(entityCatch).then((res) =>{})
            this.$emit('catchIDEvent',entityCatch)
        },
        /* 新增点位 */
        addPoints(viewer){
            // POI
            CesiumData.PointDataPOI.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 210,
                        // 高度（以像素为单位）
                        height: 126,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 民宅
            CesiumData.PointDataMZ.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                       // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 高清监控
            CesiumData.PointDataVIDEO.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 党员包保
            CesiumData.PointDataDYBB.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 事件类型
            CesiumData.PointDataEvent.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                       // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 水域监控
            CesiumData.PointDataRiver.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 高清监控
            CesiumData.PointDataCamera.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
            // 人员轨迹点
            CesiumData.LocationPoint.forEach((local)=>{
                viewer.entities.add({
                    id: local.id,
                    name: local.text,
                    position: local.Cartesian3,
                    status: local.status,
                    type: local.type,
                    etype: local.etype,
                    billboard: {
                        // 图像地址，URI或Canvas的属性
                        image: this.iconDics[local.img],
                        // 宽度（以像素为单位）
                        width: 100,
                        // 高度（以像素为单位）
                        height: 80,
                        // 逆时针旋转
                        rotation: 0,
                        // 大小是否以米为单位
                        sizeInMeters: false,
                        // 相对于坐标的垂直位置
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        // 相对于坐标的水平位置
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        // 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
                        pixelOffset: new Cesium.Cartesian2(0, 0),
                        // 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
                        scale: 1.0
                    }
                });
                viewer.entities.getById(local.id).show = false;
            })
        },
        /* 新增区域 */
        addAreas(viewer){
            CesiumData.AreaDataUse.forEach((builds) =>{
                viewer.entities.add({
                    id: builds.id,
                    name : builds.text,
                    status: builds.status,
                    type: builds.type,
                    etype: builds.etype,
                    polygon : {
                        hierarchy : builds.hierarchy,
                        extrudedHeight: builds.extrudedHeight,
                        perPositionHeight : true,
                        material : Cesium.Color.fromCssColorString(builds.color).withAlpha(0.3),
                        outline : true,
                        outlineColor: Cesium.Color.fromCssColorString(builds.bordercolor),
                        outerWidth: 14
                    }
                });
                viewer.entities.getById(builds.id).show = false;
            })
        },
        /* 新增迁徙图 */
        addFlyline(viewer){
            VIEWSITE._primitives = []
            CesiumData.FlylineData.forEach((ele) =>{
                //这里通过算法得到曲线
                let mm = this.parabola(ele.points)
                var polyline = new Cesium.PolylineGeometry({
                    positions: mm,
                    width : 4
                });
                const instance = new Cesium.GeometryInstance({
                    geometry: polyline,
                    id: ele.id,
                })
                //添加至场景
                var Pri = new Cesium.Primitive({
                    id: ele.id,
                    show: true,
                    geometryInstances: [instance],
                    appearance: this.getFlylineMaterial(),
                    releaseGeometryInstances: false,
                    compressVertices: false,
                })
                viewer.scene.primitives.add(Pri)
                // 存储 
                VIEWSITE._primitives.push(Pri)
            })
        },
        /* 新增飞线迁徙图 */
        addFlylineNew(viewer){
            var flowArr = [
                [118.1879723,31.8155411],
                [118.1882028,31.8160381],
                [118.1880216,31.8166740],
                [118.1874132,31.8164688],
                [118.1872998,31.8149802],
                [118.1883917,31.8145059]
            ]
            var flowConfigForm = {
                width: 4,
                color:'1.0, 1.0, 0.0, 0.8',
                num: 3,
                speed: 10,
                percent: 0.3,
                height: 30,
            }
            var flowEntityCollection = new Cesium.CustomDataSource({
                id: '党建引领01'
            })
            VIEWSITE._flowEntityCollection = flowEntityCollection
            let arr = flowArr.slice(0,1)
            let _center = [arr[0][0],arr[0][1]]
            let _positions =  flowArr.slice(1)
            let _this = this
            let colorArr = flowConfigForm.color.split(',')
            let color = {
                r: parseFloat(colorArr[0]),
                g: parseFloat(colorArr[1]),
                b: parseFloat(colorArr[2]),
                a: parseFloat(colorArr[3]),
            }
            viewer.dataSources.add(flowEntityCollection);
            _positions.forEach(item => {
                let _siglePositions = parabola(_center, item, 1, flowConfigForm.height);
                // 创建飞线
                for (let i = 0; i < flowConfigForm.num; i++) {
                    flowEntityCollection.entities.add(new Cesium.Entity({
                        polyline: {
                            width: flowConfigForm.width,
                            positions: _siglePositions,
                            material: new Cesium.LineFlowMaterialProperty({
                                color: new Cesium.Color(color.r, color.g, color.b,color.a),
                                speed: flowConfigForm.speed,
                                percent: flowConfigForm.percent,
                                gradient: 0.01
                            }),
                        },
                    }));
                }
                // 创建轨迹线
                flowEntityCollection.entities.add({
                    polyline: {
                        width: flowConfigForm.width,
                        positions: _siglePositions,
                        material: new Cesium.Color(color.r, color.g, color.b,0.2),
                    }
                })
            });
            function parabola( startPosition, endPosition, height = 0, maxHeight, count = 10){
                //方程 y=-(4h/L^2)*x^2+h h:顶点高度 L：横纵间距较大者
                let result = []
                height = Math.max(+height, maxHeight)
                count = Math.max(+count, 20)
                let diffLon = Math.abs(startPosition[0] - endPosition[0])
                let diffLat = Math.abs(startPosition[1] - endPosition[1])
                let L = Math.max(diffLon, diffLat)
                let dlt = L / count
                if (diffLon > diffLat) {
                    //base on lon
                    let delLat = (endPosition[1] - startPosition[1]) / count
                    if (startPosition[0] - endPosition[0] > 0) {
                        dlt = -dlt
                    }
                    for (let i = 0; i < count; i++) {
                        let h =
                            height -
                            (Math.pow(-0.5 * L + Math.abs(dlt) * i, 2) * 4 * height) /
                            Math.pow(L, 2)
                        let lon = startPosition[0] + dlt * i
                        let lat = startPosition[1] + delLat * i
                        let point = new Cesium.Cartesian3.fromDegrees(lon, lat, h);
                        result.push(point);
                    }
                } else {
                    //base on lat
                    let delLon = (endPosition[0] - startPosition[0]) / count
                    if (startPosition[1] - endPosition[1] > 0) {
                        dlt = -dlt
                    }
                    for (let i = 0; i < count; i++) {
                        let h =
                            height -
                            (Math.pow(-0.5 * L + Math.abs(dlt) * i, 2) * 4 * height) /
                            Math.pow(L, 2)
                        let lon = startPosition[0] + delLon * i
                        let lat = startPosition[1] + dlt * i
                        let point = new Cesium.Cartesian3.fromDegrees(lon, lat, h);
                        result.push(point);
                    }
                }
                return result
            }
        },
        /* 工具 - 生成抛物线 */
        parabola(twoPoints){
            let s = []
            let startPoint = [twoPoints[0],twoPoints[1],0]; //起点的经度、纬度
            s = s.concat(startPoint)
            let step = 80;  //线的多少，越多则越平滑(但过多浏览器缓存也会占用越多)
            let heightProportion = 0.125; //最高点和总距离的比值
            let dLon = (twoPoints[2] - startPoint[0])/step;  //经度差值
            let dLat = (twoPoints[3] - startPoint[1])/step;  //纬度差值
            let deltaLon = dLon * Math.abs(111000*Math.cos(twoPoints[1]));  //经度差(米级)
            let deltaLat = dLat * 111000;  //纬度差(米),1纬度相差约111000米
            let endPoint = [0,0,0];  //定义一个端点（后面将进行startPoint和endPoint两点画线）
            let heigh = (step * Math.sqrt(deltaLon*deltaLon+deltaLat*deltaLat) * heightProportion).toFixed(0)*2;
            let x2 = (10000*Math.sqrt(dLon*dLon+dLat*dLat)); //小数点扩大10000倍，提高精确度
            let a = (heigh/(x2*x2));
            function y(x,height) { return height - a*x*x; }
            for(var i = 1;i <= step; i++){  //逐“帧”画线
                endPoint[0] = startPoint[0] + dLon; //更新end点经度
                endPoint[1] = startPoint[1] + dLat; //更新end点纬度
                let x = x2*(2*i/step-1);  //求抛物线函数x
                endPoint[2] = (y(x,heigh)).toFixed(0)*1;  //求end点高度
                s = s.concat(endPoint)
                
                // end点变为start点
                startPoint[0] = endPoint[0];
                startPoint[1] = endPoint[1];
                startPoint[2] = endPoint[2];
            }
            return Cesium.Cartesian3.fromDegreesArrayHeights(s)
        },
        /* 工具 - 生成抛物线材质 */
        getFlylineMaterial(){
            // 创建材质，在MaterialAppearance中若不添加基础材质，模型将会透明
            var material = new Cesium.Material.fromType('Color')
            material.uniforms.color = Cesium.Color.ORANGE
            // 飞线效果-飞线间隔，宽度2
            let fragmentShaderSource = `
                        varying vec2 v_st;    
                        varying float v_width;    
                        varying float v_polylineAngle;
                        varying vec4 v_positionEC;
                        varying vec3 v_normalEC;
                        void main()
                        {
                            vec2 st = v_st;
                            // 箭头飞线，宽度 8
                            float xx = fract(st.s*10.0 + st.t  - czm_frameNumber/60.0);
                            if (st.t<0.5) {
                                xx = fract(st.s*10.0 - st.t - czm_frameNumber/60.0);
                            }
                            float r = 0.0;
                            float g = xx;
                            float b = xx;
                            float a = xx;

                            // 飞线边框
                            if (st.t>0.8||st.t<0.2) {
                                g = 1.0;
                                b = 1.0;
                                a = 0.4;
                            }

                            gl_FragColor = vec4(r,g,b,a);
                        }

                `
            // 自定义材质
            const aper = new Cesium.PolylineMaterialAppearance({
                material: material,
                translucent: true,
                vertexShaderSource: `
                        #define CLIP_POLYLINE 
                        void clipLineSegmentToNearPlane(
                            vec3 p0,
                            vec3 p1,
                            out vec4 positionWC,
                            out bool clipped,
                            out bool culledByNearPlane,
                            out vec4 clippedPositionEC)
                        {
                            culledByNearPlane = false;
                            clipped = false;
                            vec3 p0ToP1 = p1 - p0;
                            float magnitude = length(p0ToP1);
                            vec3 direction = normalize(p0ToP1);
                            float endPoint0Distance =  czm_currentFrustum.x + p0.z;
                            float denominator = -direction.z;
                            if (endPoint0Distance > 0.0 && abs(denominator) < czm_epsilon7)
                            {
                                culledByNearPlane = true;
                            }
                            else if (endPoint0Distance > 0.0)
                            {
                                float t = endPoint0Distance / denominator;
                                if (t < 0.0 || t > magnitude)
                                {
                                    culledByNearPlane = true;
                                }
                                else
                                {
                                    p0 = p0 + t * direction;
                                    p0.z = min(p0.z, -czm_currentFrustum.x);
                                    clipped = true;
                                }
                            }
                            clippedPositionEC = vec4(p0, 1.0);
                            positionWC = czm_eyeToWindowCoordinates(clippedPositionEC);
                        }
                        vec4 getPolylineWindowCoordinatesEC(vec4 positionEC, vec4 prevEC, vec4 nextEC, float expandDirection, float width, bool usePrevious, out float angle)
                        {
                            #ifdef POLYLINE_DASH
                            vec4 positionWindow = czm_eyeToWindowCoordinates(positionEC);
                            vec4 previousWindow = czm_eyeToWindowCoordinates(prevEC);
                            vec4 nextWindow = czm_eyeToWindowCoordinates(nextEC);
                            vec2 lineDir;
                            if (usePrevious) {
                                lineDir = normalize(positionWindow.xy - previousWindow.xy);
                            }
                            else {
                                lineDir = normalize(nextWindow.xy - positionWindow.xy);
                            }
                            angle = atan(lineDir.x, lineDir.y) - 1.570796327;
                            angle = floor(angle / czm_piOverFour + 0.5) * czm_piOverFour;
                            #endif
                            vec4 clippedPrevWC, clippedPrevEC;
                            bool prevSegmentClipped, prevSegmentCulled;
                            clipLineSegmentToNearPlane(prevEC.xyz, positionEC.xyz, clippedPrevWC, prevSegmentClipped, prevSegmentCulled, clippedPrevEC);
                            vec4 clippedNextWC, clippedNextEC;
                            bool nextSegmentClipped, nextSegmentCulled;
                            clipLineSegmentToNearPlane(nextEC.xyz, positionEC.xyz, clippedNextWC, nextSegmentClipped, nextSegmentCulled, clippedNextEC);
                            bool segmentClipped, segmentCulled;
                            vec4 clippedPositionWC, clippedPositionEC;
                            clipLineSegmentToNearPlane(positionEC.xyz, usePrevious ? prevEC.xyz : nextEC.xyz, clippedPositionWC, segmentClipped, segmentCulled, clippedPositionEC);
                            if (segmentCulled)
                            {
                                return vec4(0.0, 0.0, 0.0, 1.0);
                            }
                            vec2 directionToPrevWC = normalize(clippedPrevWC.xy - clippedPositionWC.xy);
                            vec2 directionToNextWC = normalize(clippedNextWC.xy - clippedPositionWC.xy);
                            if (prevSegmentCulled)
                            {
                                directionToPrevWC = -directionToNextWC;
                            }
                            else if (nextSegmentCulled)
                            {
                                directionToNextWC = -directionToPrevWC;
                            }
                            vec2 thisSegmentForwardWC, otherSegmentForwardWC;
                            if (usePrevious)
                            {
                                thisSegmentForwardWC = -directionToPrevWC;
                                otherSegmentForwardWC = directionToNextWC;
                            }
                            else
                            {
                                thisSegmentForwardWC = directionToNextWC;
                                otherSegmentForwardWC =  -directionToPrevWC;
                            }
                            vec2 thisSegmentLeftWC = vec2(-thisSegmentForwardWC.y, thisSegmentForwardWC.x);
                            vec2 leftWC = thisSegmentLeftWC;
                            float expandWidth = width * 0.5;
                            if (!czm_equalsEpsilon(prevEC.xyz - positionEC.xyz, vec3(0.0), czm_epsilon1) && !czm_equalsEpsilon(nextEC.xyz - positionEC.xyz, vec3(0.0), czm_epsilon1))
                            {
                                vec2 otherSegmentLeftWC = vec2(-otherSegmentForwardWC.y, otherSegmentForwardWC.x);
                                vec2 leftSumWC = thisSegmentLeftWC + otherSegmentLeftWC;
                                float leftSumLength = length(leftSumWC);
                                leftWC = leftSumLength < czm_epsilon6 ? thisSegmentLeftWC : (leftSumWC / leftSumLength);
                                vec2 u = -thisSegmentForwardWC;
                                vec2 v = leftWC;
                                float sinAngle = abs(u.x * v.y - u.y * v.x);
                                expandWidth = clamp(expandWidth / sinAngle, 0.0, width * 2.0);
                            }
                            vec2 offset = leftWC * expandDirection * expandWidth * czm_pixelRatio;
                            return vec4(clippedPositionWC.xy + offset, -clippedPositionWC.z, 1.0) * (czm_projection * clippedPositionEC).w;
                        }
                        vec4 getPolylineWindowCoordinates(vec4 position, vec4 previous, vec4 next, float expandDirection, float width, bool usePrevious, out float angle)
                        {
                            vec4 positionEC = czm_modelViewRelativeToEye * position;
                            vec4 prevEC = czm_modelViewRelativeToEye * previous;
                            vec4 nextEC = czm_modelViewRelativeToEye * next;
                            return getPolylineWindowCoordinatesEC(positionEC, prevEC, nextEC, expandDirection, width, usePrevious, angle);
                        }

                        attribute vec3 position3DHigh;
                        attribute vec3 position3DLow;
                        attribute vec3 prevPosition3DHigh;
                        attribute vec3 prevPosition3DLow;
                        attribute vec3 nextPosition3DHigh;
                        attribute vec3 nextPosition3DLow;
                        attribute vec2 expandAndWidth;
                        attribute vec2 st;
                        attribute float batchId;

                        varying float v_width;
                        varying vec2 v_st;
                        varying float v_polylineAngle;
                        
                        varying vec4 v_positionEC;
                        varying vec3 v_normalEC;
                        void main()
                        {
                        float expandDir = expandAndWidth.x;
                        float width = abs(expandAndWidth.y) + 0.5;
                        bool usePrev = expandAndWidth.y < 0.0;

                        vec4 p = czm_computePosition();
                        vec4 prev = czm_computePrevPosition();
                        vec4 next = czm_computeNextPosition();
                        
                        float angle;
                        vec4 positionWC = getPolylineWindowCoordinates(p, prev, next, expandDir, width, usePrev, angle);
                        gl_Position = czm_viewportOrthographic * positionWC;
                        
                        v_width = width;
                        v_st.s = st.s;
                        v_st.t = st.t;
                        // v_st.t = czm_writeNonPerspective(st.t, gl_Position.w);
                        v_polylineAngle = angle;


                        
                        vec4 eyePosition = czm_modelViewRelativeToEye * p;
                        v_positionEC =  czm_inverseModelView * eyePosition;      // position in eye coordinates
                        //v_normalEC = czm_normal * normal;                         // normal in eye coordinates
                        }

                    `,
                fragmentShaderSource: fragmentShaderSource
            })
            return aper;
        },
        /* 新增路径 */
        addTransferInfo(viewer){
            VIEWSITE._personStepLines = []
            var scene = viewer.scene;
            var material=new Cesium.Material({
                fabric:{
                    uniforms:{
                        u_itime: 10.0,
                        u_trailpercent: 0.3,
                        u_trailmovespeed: 10.0/50.0,
                    }
                }
            });
            var primitive = new Cesium.Primitive({
            geometryInstances: new Cesium.GeometryInstance({
                geometry: new Cesium.PolylineGeometry({
                positions: CesiumData.LocationLine,
                width: 4,
                vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
                colorsPerVertex: true,
                colors: [
                    new Cesium.Color(0.1), 
                    new Cesium.Color(0.2), 
                    new Cesium.Color(0.3), 
                    new Cesium.Color(0.4), 
                    new Cesium.Color(0.5),
                    new Cesium.Color(0.6),
                    new Cesium.Color(0.7),
                    new Cesium.Color(0.8),
                    new Cesium.Color(0.9),
                    new Cesium.Color(1.0),
                ],
                }),
            }),
            appearance: new Cesium.PolylineColorAppearance({
                translucent: false,
                fragmentShaderSource: `precision highp float;
                varying vec4 v_color;
                void main(){
                float r=v_color.r-u_itime_0*u_trailmovespeed_2;
                float dist=smoothstep(0.0,u_trailpercent_1,mod(r,u_trailpercent_1));
                gl_FragColor=mix(vec4(0,125,125,0.5),vec4(0),dist);
                }
                `,
            }),
            });
            primitive.appearance.material=material;
            scene.primitives.add(primitive);
            scene.preRender.addEventListener(function(s,t){
                let elaspTime=Cesium.JulianDate.now().secondsOfDay-t.secondsOfDay;
                primitive.appearance.material.uniforms.u_itime=elaspTime;
            });
            // 存储
            VIEWSITE._personStepLines.push(primitive)
        },
        /* 新增建筑单体化 */
        addShapeTile(viewer){
            // 矢量面贴对象的底部高程
            Cesium.GroundPrimitive.bottomAltitude = Number(0)
            // 矢量面贴对象的拉伸高度
            Cesium.GroundPrimitive.extrudeHeight = Number(0)
            viewer.entities.add({
                id: 'id',
                name: 'id',
                polygon: {
                    hierarchy: CesiumData.AreaData[0].hierarchy,
                    material: Cesium.Color.fromCssColorString(CesiumData.AreaData[0].bordercolor).withAlpha(0.5)
                },
                clampToS3M: true // 贴在S3M模型表面
            })
        },
        // 走廊通道
        addEntityCorridor() {
            let entityCorridor = new Cesium.Entity({
                id: 'entityCorridor0',
                name: 'entityCorridor0',
                position: Cesium.Cartesian3.fromDegrees(123.0, 43.0),
                corridor: {
                    positions: Cesium.Cartesian3.fromDegreesArray([
                        123.0, 40.0, 122.0, 40.0, 123.0, 42.0, 121.0, 42.0
                    ]),
                    width: 10000,
                    material: new Cesium.ImageMaterialProperty({
                                        //可以设置贴图
                        // image: 'static/image/corridor.png',
                        color: Cesium.Color.YELLOWGREEN,
                        repeat: new Cesium.Cartesian2(32, 32)
                        // transparent: true
                    }),
                    extrudedHeight: 10000,
                    outline: false
                }
            })
            let corridorGeom = window.viewer.entities.add(entityCorridor)
            window.viewer.zoomTo(entityCorridor)
        },
    }
}
</script>

<style lang="less" scoped>
    .cesium{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
        z-index: 98;
    }
</style>